window.consentCookie = {
    preferences: false,
    statistics: false,
    marketing: false,
    saved: false
}

window.resetConsentCookie = () => {
    window.consentCookie = {
        preferences: false,
        statistics: false,
        marketing: false,
        saved: false
    }
}

window.getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

window.setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

window.setCookieConsent = (preferences = false, statistics = false, marketing = false) => {
    consentCookie.preferences = preferences
    consentCookie.statistics = statistics
    consentCookie.marketing = marketing
    consentCookie.saved = true
    setCookie('cookieConsent', JSON.stringify(consentCookie), 365);
    checkCookieConsent();
}

window.setGoogleTagManager = () => {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});let f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W3WS3ZJ');


}

window.isFbSet = false
window.setFbPixel = () => {
    !function(f,b,e,v,n,t,s) {
        if(f.fbq) return;
        n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n,arguments):n.queue.push(arguments)
        };
        if(!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
    } (window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '483271945555693');
    fbq('track', 'PageView');
    window.isFbSet = true
}

window.checkCookieConsent = () => {
    setGoogleTagManager()

    let consentCookieData = getCookie('cookieConsent');
    if (consentCookieData !== null) {
        window.consentCookie = JSON.parse(consentCookieData);
        window.dataLayer = window.dataLayer || [];

        if (consentCookie.marketing) {
            setFbPixel()
            dataLayer.push({'event': 'consentAll'})
        } else {
            dataLayer.push({'event': 'consentNone'})
        }
    }
}

domReady(() => {
    checkCookieConsent();
    if(!consentCookie.saved) {
        showCookieConsent();
    }
})

function domReady(callback) {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

function showCookieConsent() {
    let cookieConsentElement = document.getElementById('cookieConsent')
    if (consentCookie.preferences) {

    }
    if (consentCookie.statistics) {
        cookieConsentElement.querySelector('#statisticCookies').checked = true
    }
    if (consentCookie.marketing) {
        cookieConsentElement.querySelector('#marketingCookies').checked = true
    }
    cookieConsentElement.style.display = 'block'
}

let cookieDetailContainer = document.getElementById('showCookieDetails')
cookieDetailContainer.addEventListener('click', (event) => {
    event.preventDefault()
    event.target.querySelectorAll('i').forEach((element) => {
        element.classList.toggle('fa-rotate-180')
    })
    document.getElementById('cookieDetails').slideToggle()
})

let saveCookieSettingsIndividualBtn = document.getElementById('saveCookieSettingsIndividual')
saveCookieSettingsIndividualBtn.addEventListener('click', (event) => {
    event.preventDefault()
    let cookieConsentElement = document.getElementById('cookieConsent')
    let cookieSettingsForm = document.getElementById('cookieSettings')
    resetConsentCookie()
    let data = cookieSettingsForm.serializeArray().reduce((obj, item) => {
        obj[item.name] = item.value;
        return obj;
    }, {})
    for (const val in data) {
        switch(val) {
            case 'statisticCookies':
                consentCookie.statistics = true;
                break;
            case 'marketingCookies':
                consentCookie.marketing = true;
                break;
        }
    }
    setCookieConsent(consentCookie.preferences, consentCookie.statistics, consentCookie.marketing);
    cookieConsentElement.style.display = 'none'
})

let saveCookieSettingsAllBtn = document.getElementById('saveCookieSettingsAll')
saveCookieSettingsAllBtn.addEventListener('click', (event) => {
    event.preventDefault()
    let cookieConsentElement = document.getElementById('cookieConsent')
    resetConsentCookie();
    setCookieConsent(true, true, true);
    cookieConsentElement.style.display = 'none'
})

let changeCookieSettingsBtn = document.getElementById('changeCookieSettings')
changeCookieSettingsBtn.addEventListener('click', (event) => {
    event.preventDefault()
    showCookieConsent();
})
